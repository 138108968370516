import AnimationCardReveal from "@components/animations/AnimationCardReveal";
import LinearContainer from "@components/ui/linear-container/linear-container";

interface ICourseCard {
  selected: boolean;
  index: number;
  thumbnail: string;
  title: string;
  description: string;
  duration?: string;
  order: string;
}

const CourseCard = (props: ICourseCard) => {
  return (
    <AnimationCardReveal>
      <div
        className={`section-card flex justify-between lg:gap-[128px] min-h-[840px] items-center py-24 max-lg:flex-col-reverse max-lg:items-end max-lg:pl-4  ${
          props.selected ? "opacity-100" : "opacity-40"
        }`}
        dir="rtl"
      >
        <div className="flex justify-start w-full">
          <div className="text-right flex flex-col items-start gap-[24px] max-w-[480px] section-details">
            <h4 className="text-grey-w0 text-h4">{props.title}</h4>
            <img
              src={props.thumbnail}
              alt={props.title}
              className="w-full max-w-[450px] h-[300px] object-contain"
              width={338}
              height={241}
            />
            <p className="font-medium text-grey-w1 text-md md:text-xl antialiased tracking-wide">
              {props.description}
            </p>

            {props.duration && (
              <LinearContainer className="flex items-center px-3 py-1.5">
                <div className="font-bold text-grey-w0 tracking-wide">
                  <b className="text-grey-w1">طول الحصة: </b>
                  {props.duration}
                </div>
              </LinearContainer>
            )}
          </div>
        </div>
        <div className="relative w-full lg:h-[700px]">
          <h1 className="top-[50svh] sticky font-geDinarone text-center text-grey-w0 text-h1 max-lg:text-start max-lg:text-h2">
            <span className="text-right font-bold font-sora text-[#3fba7f] text-[140px] max-lg:text-[64px] tracking-[-1.4px] max-lg:tracking-[-0.6125px]">
              {props.index + 1}
            </span>
            {props.order}
          </h1>
        </div>
      </div>
    </AnimationCardReveal>
  );
};

export default CourseCard;

import { useInView, useAnimation, motion } from "framer-motion";
import { type ReactNode, useRef, useEffect } from "react";
import { useMediaQuery } from "react-responsive";

const AnimationCardReveal = ({ children }: { children: ReactNode }) => {
  const isSmallScreen = useMediaQuery({
    query: "(max-width: 504px)",
  });
  const cardRef = useRef(null);
  const isInView = useInView(cardRef, { margin: isSmallScreen ? "-200px" : '-500px', once: true });
  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) mainControls.start("visible");
  }, [isInView]);
  return (
    <motion.div
      ref={cardRef}
      variants={cardInViewVarients}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 0.3, delay: 0.15 }}
    >
      {children}
    </motion.div>
  );
};

export default AnimationCardReveal;

const cardInViewVarients = {
  hidden: { opacity: 0.2 },
  visible: { opacity: 1 },
};

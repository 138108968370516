import type { IGlobalSvgProps } from "./svgTypes"


const ScrollArrow = ({ width, height , className }: IGlobalSvgProps) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ? width : 14}
    height={height ? height : 12}
    viewBox="0 0 14 12"
    fill="none"
    className={className}
  >
    <g opacity="0.5">
      <path
        d="M7.58488 11.6827L13.8985 1.67605C13.9609 1.57416 13.9946 1.45733 13.996 1.33789C13.9975 1.21844 13.9667 1.10082 13.9068 0.997445C13.847 0.894067 13.7603 0.808767 13.656 0.750555C13.5517 0.692343 13.4336 0.663378 13.3142 0.666718L0.686215 0.666718C0.566669 0.663068 0.448361 0.691806 0.343813 0.749893C0.239264 0.80798 0.152365 0.893253 0.0923138 0.996686C0.0322628 1.10012 0.00129414 1.21786 0.00268555 1.33746C0.004076 1.45705 0.037775 1.57404 0.100216 1.67605L6.41555 11.6827C6.47886 11.7801 6.56551 11.8602 6.66761 11.9156C6.76972 11.971 6.88405 12 7.00022 12C7.11639 12 7.23072 11.971 7.33282 11.9156C7.43492 11.8602 7.52157 11.7801 7.58488 11.6827Z"
        fill="url(#paint0_linear_180_529)"
      />
      <path
        d="M7.58488 11.6827L13.8985 1.67605C13.9609 1.57416 13.9946 1.45733 13.996 1.33789C13.9975 1.21844 13.9667 1.10082 13.9068 0.997445C13.847 0.894067 13.7603 0.808767 13.656 0.750555C13.5517 0.692343 13.4336 0.663378 13.3142 0.666718L0.686215 0.666718C0.566669 0.663068 0.448361 0.691806 0.343813 0.749893C0.239264 0.80798 0.152365 0.893253 0.0923138 0.996686C0.0322628 1.10012 0.00129414 1.21786 0.00268555 1.33746C0.004076 1.45705 0.037775 1.57404 0.100216 1.67605L6.41555 11.6827C6.47886 11.7801 6.56551 11.8602 6.66761 11.9156C6.76972 11.971 6.88405 12 7.00022 12C7.11639 12 7.23072 11.971 7.33282 11.9156C7.43492 11.8602 7.52157 11.7801 7.58488 11.6827Z"
        fill="url(#paint1_linear_180_529)"
      />
      <path
        d="M7.58488 11.6827L13.8985 1.67605C13.9609 1.57416 13.9946 1.45733 13.996 1.33789C13.9975 1.21844 13.9667 1.10082 13.9068 0.997446C13.847 0.894069 13.7603 0.808769 13.656 0.750557C13.5517 0.692345 13.4336 0.663378 13.3142 0.666718L0.686214 0.666718C0.566669 0.663068 0.448361 0.691808 0.343813 0.749894C0.239264 0.807981 0.152365 0.893254 0.0923138 0.996687C0.0322628 1.10012 0.00129318 1.21786 0.00268459 1.33746C0.004076 1.45705 0.0377741 1.57404 0.100215 1.67605L6.41555 11.6827C6.47886 11.7801 6.56551 11.8602 6.66761 11.9156C6.76972 11.971 6.88405 12 7.00022 12C7.11639 12 7.23072 11.971 7.33282 11.9156C7.43492 11.8602 7.52157 11.7801 7.58488 11.6827Z"
        fill="url(#paint2_linear_180_529)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_180_529"
        x1="7.00022"
        y1="12.0001"
        x2="7.00022"
        y2="0.666717"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3FBA7F" stopOpacity={0} />
        <stop offset="0.406" stopColor="#3FBA7F" stopOpacity="0.011" />
        <stop offset="0.553" stopColor="#3FBA7F" stopOpacity="0.052" />
        <stop offset="0.657" stopColor="#3FBA7F" stopOpacity="0.123" />
        <stop offset="0.741" stopColor="#3FBA7F" stopOpacity="0.225" />
        <stop offset="0.813" stopColor="#3FBA7F" stopOpacity="0.358" />
        <stop offset="0.877" stopColor="#3FBA7F" stopOpacity="0.522" />
        <stop offset="0.935" stopColor="#3FBA7F" stopOpacity="0.717" />
        <stop offset="0.987" stopColor="#3FBA7F" stopOpacity="0.936" />
        <stop offset={1} stopColor="#3FBA7F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_180_529"
        x1="13.5929"
        y1="0.475051"
        x2="3.15022"
        y2="6.74972"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3FBA7F" stopOpacity={0} />
        <stop offset="0.406" stopColor="#3FBA7F" stopOpacity="0.011" />
        <stop offset="0.553" stopColor="#3FBA7F" stopOpacity="0.052" />
        <stop offset="0.657" stopColor="#3FBA7F" stopOpacity="0.123" />
        <stop offset="0.741" stopColor="#3FBA7F" stopOpacity="0.225" />
        <stop offset="0.813" stopColor="#3FBA7F" stopOpacity="0.358" />
        <stop offset="0.877" stopColor="#3FBA7F" stopOpacity="0.522" />
        <stop offset="0.935" stopColor="#3FBA7F" stopOpacity="0.717" />
        <stop offset="0.987" stopColor="#3FBA7F" stopOpacity="0.936" />
        <stop offset={1} stopColor="#3FBA7F" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_180_529"
        x1="0.641217"
        y1="0.153718"
        x2="10.7912"
        y2="6.74505"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3FBA7F" stopOpacity={0} />
        <stop offset="0.406" stopColor="#3FBA7F" stopOpacity="0.011" />
        <stop offset="0.553" stopColor="#3FBA7F" stopOpacity="0.052" />
        <stop offset="0.657" stopColor="#3FBA7F" stopOpacity="0.123" />
        <stop offset="0.741" stopColor="#3FBA7F" stopOpacity="0.225" />
        <stop offset="0.813" stopColor="#3FBA7F" stopOpacity="0.358" />
        <stop offset="0.877" stopColor="#3FBA7F" stopOpacity="0.522" />
        <stop offset="0.935" stopColor="#3FBA7F" stopOpacity="0.717" />
        <stop offset="0.987" stopColor="#3FBA7F" stopOpacity="0.936" />
        <stop offset={1} stopColor="#3FBA7F" />
      </linearGradient>
    </defs>
  </svg>
  )
}

export default ScrollArrow
import { motion, useScroll, useTransform } from "framer-motion";
import { useLayoutEffect, useRef, useState } from "react";
import ScrollArrow from "src/svg/ScrollArrow";

const AnimationProgressBar = ({ topGap = 0 }: { topGap: number }) => {
  const progressRef = useRef<HTMLDivElement>(null);
  const [progressFullLength, setProgressFullLength] = useState(0);

  useLayoutEffect(() => {
    const handleResize = () => {
      if (progressRef.current) {
        const progressClientRect = progressRef.current.getBoundingClientRect();
        setProgressFullLength(progressClientRect.height);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (progressRef.current) {
      resizeObserver.observe(progressRef.current);
    }
    handleResize();
    return () => {
      if (progressRef.current) {
        resizeObserver.unobserve(progressRef.current);
      }
    };
  }, [progressRef.current]);

  const { scrollYProgress } = useScroll({
    target: progressRef,
    offset: [`${topGap + 200}px end`, "end end"],
  });

  const height = useTransform(
    scrollYProgress,
    [0, 1],
    ["20px", `${Math.round(progressFullLength) - topGap - 172}px`]
  );
  const y = useTransform(
    scrollYProgress,
    [0, 1],
    ["20px", `${Math.round(progressFullLength) - topGap - 172}px`]
  );
  return (
    <div
      className="left-[50%] max-lg:left-4 absolute mt-6 h-full"
      ref={progressRef}
      style={{ top: `${topGap + 32}px` }}
    >
      <motion.div
        className="z-[1] absolute bg-progress-linear shadow-progress-shadow w-[2px] transition-all duration-200 ease-out"
        style={{ height }}
      ></motion.div>
      <motion.div
        style={{ y }}
        className="transition-all duration-200 ease-out"
      >
        <ScrollArrow className="-top-0.5 left-[calc(50%_-_7px)] absolute" />
      </motion.div>
      <div
        className="bg-progress-highlight w-[2px] h-full"
        style={{
          height: `calc(100% - ${topGap + 192}px)`,
        }}
      />
    </div>
  );
};

export default AnimationProgressBar;

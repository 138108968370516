export const coursesSectionCopy = {
  title: "شنوا بش نتعلم؟",
  subtitle:
    "بش تنجم تنجح و توصل للهدف متاعك فما منهجية لازمك تبعها بداية من Mindset العقلية للأساسيات للحاجات المتقدمة و هذا لي كان وراه ترتيب الدورات المسجلة هذي  بجودة عالية و كيفاش حتى تسوق المهارة هذي.",
  courses: [
    {
      order: "الدورة الأولى",
      thumbnail: "images/courses-section/course_1.webp",
      title: "Freelance Mastery",
      description:
        "دورة شاملة للعمل الحر تشمل المهارات الأساسية، بناء العقلية، تقنيات البيع، الدفع، وتحسين تقديم الخدمات. تعلم كيفية التوسع وتطوير عملك بنجاح.",
      duration: "3 ساعات و 10 دقيقة",
    },
    {
      order: "الدورة الثانية",
      thumbnail: "images/courses-section/course_2.webp",
      title: "Content Craft",
      description:
        "Course تعلمك صناعة المحتوى ,كيفاش تعمل اول فيديو حتى بتاليفون, كيفاش تنجم تستغلها ك Freelancer و كيفاش حتى تجيب برشا Views",
      duration: "1 ساعات و 32 دقيقة",
    },
    {
      order: "الدورة الثالثة",
      thumbnail: "images/courses-section/course_4.webp",
      title: "Shorts Playbook",
      description:
        "تحب تتعلم Editing كما فديوات قصي بضبط ؟ سيف و قصي بش يعلموك من صفر كيفاش تنجم تمنتج فديوات بجودة عالية و كيفاش حتى تسوق المهارة هذي.",
      duration: "3 ساعات و 11 دقيقة",
    },
    {
      order: "الدورة الرابعة",
      thumbnail: "images/courses-section/course_3.webp",
      title: "Meta School",
      description:
        "Media Buying او ما يعرف ب Sponsoring في تونس مهارة مهمة لأي صاحب مشروع و قادرة تدخلو برشا فلوس. اذاكا علاه تنجم تعرضها على اي مشروع و تخلص و هذا شنوة بش تتعلم مع بشير الي استغلها و دخل منها فلوس كFreelancer.",
      duration: "3 ساعات",
    },
    {
      order: "الدورة الخامسة",
      thumbnail: "images/courses-section/course_5.webp",
      title: "Advanced Acquisition",
      description:
        "انك تجيب Clients أهم حاجة في أي مشروع، في هذي بش يعلمكم بشير كيفاش تنجم تجيب برشة Clients و تعمل برشة Systems متاع عباد كاسحة. بشير عندو Clients Agency تخدم مع حرفاء من إفريقيا الكل و ياخو ملاين شهريا على كل واحد.",
      duration: "1 ساعات و 20 دقيقة",
    },
    {
      order: "مفاجأة",
      thumbnail: "images/courses-section/surprise.webp",
      title: "Secret Bonus",
      description: "",
    },
  ],
};

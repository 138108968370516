import MainTitle from "@components/ui/MainTitle";
import Section from "@components/ui/Section";

import AnimationProgressBar from "@components/animations/AnimationProgressBar";
import AnimationSectionBody from "@components/animations/AnimationSectionBody";
import { useEffect, useRef, useState } from "react";
import { coursesSectionCopy } from "src/content/courses";
import { v4 as uuidv4 } from "uuid";
import CourseCard from "./CourseCard";
import "./coursessecttion.scss";

const CoursesSection = () => {
  const titleRef = useRef<HTMLDivElement>(null);
  const [titleHeight, setTitleHeight] = useState(0);

  useEffect(() => {
    if (titleRef.current) {
      const height = titleRef.current.getBoundingClientRect().height;
      setTitleHeight(height + 96);
    }
  });

  return (
    <Section className="p-0 !max-w-full axis-section">
      <AnimationSectionBody titleHeight={titleHeight}>
        <div className="linear-bg">
          <img
            src="/images/LinearBackground.webp"
            alt="Linear Background"
            className="max-md:!top-[0%]"
            width={1020}
            height={1020}
          />
        </div>
        <AnimationProgressBar topGap={titleHeight} />
        <div className="relative px-3 md:px-8 lg:px-16 py-24 max-lg:p-4 max-lg:pt-16 w-full max-h-[calc(100% - 333px)]">
          <div
            className="flex flex-col items-center gap-4 mx-auto w-full max-w-[60.125rem]"
            ref={titleRef}
          >
            <MainTitle
              title={coursesSectionCopy.title}
              subTitle={coursesSectionCopy.subtitle}
            />
          </div>
        </div>
        <div className="mx-auto pr-3 md:pr-5 lg:pr-8 max-w-[1280px]">
          {coursesSectionCopy.courses.map((element, index) => {
            return (
              <CourseCard
                selected={true}
                index={index}
                thumbnail={element.thumbnail}
                title={element.title}
                description={element.description}
                duration={element.duration}
                order={element.order}
                key={uuidv4()}
              />
            );
          })}
        </div>
      </AnimationSectionBody>
    </Section>
  );
};

export default CoursesSection;

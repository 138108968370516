import { motion, useScroll, useTransform } from "framer-motion";

import { useRef, type ReactNode } from "react";
import { useMediaQuery } from "react-responsive";

const AnimationSectionBody = ({
  children,
  titleHeight,
}: {
  children: ReactNode;
  titleHeight: number;
}) => {
  const isPhone = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const sectionRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: [`${titleHeight}px end`, "end end"],
  });

  const maxWidth = useTransform(
    scrollYProgress,
    [0, 0.05, 0.95, 1],
    ["100%", "100%", "100%", "80%"]
  );

  return (
    <motion.div
      style={!isPhone ? { maxWidth } : { maxWidth: "100%" }}
      className="content"
      ref={sectionRef}
    >
      {children}
    </motion.div>
  );
};

export default AnimationSectionBody;
